/* Global imports */
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Link from "../../helper/link";
import { getValue } from "../../helper/translation";
/* Local imports */
import Box from "./box";

/* Component definition */
const Download = ({ filename, size, extension, link, locale }) => {
  const { microcopy } = useStaticQuery(graphql`
    {
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
    }
  `);

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  return (
    <Box
      headline="labels.publicationDownload"
      className="d-flex download"
      locale={locale}
    >
      <p className="download-fileinfo">
        <span className="download-filename">{filename}</span>
        <span className="download-filesize">{`${getValue(
          "labels.size",
          localizedMicrocopy
        )}: ${size}`}</span>
        <span className="download-filetype">{`${getValue(
          "labels.filetype",
          localizedMicrocopy
        )}: ${extension.toUpperCase()}`}</span>
      </p>
      <Link className="button button--inverted button--link" to={link} />
    </Box>
  );
};
/* PropTypes */
Download.propTypes = {
  filename: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  locale: PropTypes.oneOf(["de-DE", "en"]),
  extension: PropTypes.string.isRequired,
};
Download.defaultProps = {
  filename: "Dateiname.pdf",
  size: "3MB",
  link: "",
  locale: "de-DE",
};

/* Local utility functions */

/* Styles */

export default Download;
