/* Global imports */
import PropTypes from "prop-types";
import React from "react";
/* Local imports */
import Box from "./box";
import Gallery from "../gallery";

/* Component definition */
const Impressions = ({ images, locale }) => {
  return (
    <Box
      headline="labels.impressions"
      className="d-flex impressions"
      locale={locale}
    >
      <Gallery images={images} />
    </Box>
  );
};
/* PropTypes */
Impressions.propTypes = {
  locale: PropTypes.oneOf(["de-DE", "en"]),
};
Impressions.defaultProps = {
  images: [],
  locale: "de-DE",
};

/* Local utility functions */

/* Styles */

export default Impressions;
