/* Global imports */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import Link, { getLocalizedLink } from "../../helper/link";

/* Component definition */
const SidebarNavigation = ({ childPages, className, locale }) => {
  return (
    <ul className={`sidebarNavigation ${className}`}>
      {childPages &&
        childPages.map(childPage => (
          <li>
            <Link to={getLocalizedLink(`/${childPage.slug}`, locale)}>
              {childPage.localizedPage.name}
              <FontAwesomeIcon
                icon={faChevronRight}
                className={"sidebar-navigation-icon"}
              />
            </Link>
          </li>
        ))}
    </ul>
  );
};
/* PropTypes */
SidebarNavigation.propTypes = {
  childPages: PropTypes.array.isRequired,
  className: PropTypes.string,
  locale: PropTypes.string
};
SidebarNavigation.defaultProps = {
  childPages: [],
  className: "",
  locale: 'de-DE'
};

/* Local utility functions */

/* Styles */

export default SidebarNavigation;
