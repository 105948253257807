/* Global imports */
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, useStaticQuery } from "gatsby";
import * as _ from "lodash";
import "moment/locale/de";
import PropTypes from "prop-types";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carouselBreakpoints } from "../helper/carousel";
import Link, {
  getArticleOrPublicationLink,
  getLocalizedLink,
} from "../helper/link";
import {
  getCappedText,
  normalizeArticle,
  getNormalizedReader,
} from "../helper/normalizer";
import { getLocalizedTags, getValue } from "../helper/translation";
import CarouselControl from "./carouselControl";
import TeaserCard from "./teaserCard";
import { removeDuplicates } from "../helper/articles";
const generateArticleCriteria = article => {
  const topics = article.topic ? article.topic.map(topic => topic.name) : [];
  const focusOfWork = article.focusOfWork && article.focusOfWork.name;
  const region = article.region && article.region.name;

  const arr = [...topics];
  if (focusOfWork) {
    arr.push(focusOfWork);
  }
  if (region) {
    arr.push(region);
  }

  return Array.from(new Set([...arr]));
};

/* Component definition */
const RelatedArticles = ({ article, tagInfo }) => {
  const { allArticles, allPublications, microcopy } = useStaticQuery(graphql`
    {
      allPublications: allContentfulPublicationGeneral {
        edges {
          node {
            title
            contentful_id
            publicationDetail {
              contentful_id
              visibleTags
              node_locale
              __typename
              title
              document {
                title
                file {
                  url
                }
              }
              content {
                json
              }
              focusOfWork {
                contentful_id
                id
                name
              }
              organizations {
                id
                name
              }
              publicationDateTime
              topic {
                contentful_id
                id
                name
              }
              region {
                contentful_id
                id
                name
              }
              imageLandscape {
                imageLandscape {
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                    aspectRatio
                  }
                }
              }
              reader {
                json
                content {
                  content {
                    value
                  }
                }
              }
              author {
                name
              }
            }
          }
        }
      }
      microcopy: allContentfulMicrocopy {
        edges {
          node {
            node_locale
            key
            value
          }
        }
      }
      allArticles: allContentfulArticleGeneral {
        edges {
          node {
            ...articleGeneralForRelatedArticles
          }
        }
      }
    }

    fragment articleGeneralForRelatedArticles on ContentfulArticleGeneral {
      title
      id
      contentful_id
      articleDetail {
        node_locale
        __typename
        organization {
          name
        }
        imageLandscape {
          imageLandscape {
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              aspectRatio
            }
          }
        }
        articleReader {
          json
          content {
            content {
              value
            }
          }
        }
        postDateTime
        region {
          contentful_id
          id
          name
        }
        typeOfArticle {
          name
          id
        }
        articleTitle
        articleSubtitle
        visibleTags
        articleContent {
          json
        }
        authorOfPublication {
          name
        }
        focusOfWork {
          contentful_id
          name
        }
        topic {
          contentful_id
          name
        }
      }
    }
  `);

  const articleID = article.contentful_id || null;
  if (!tagInfo) {
    if (article.__typename === "ContentfulArticleGeneral") {
      article = normalizeArticle(article.articleDetail);
    } else {
      article = article.publicationDetail;
    }
  } else {
    article = tagInfo;
  }
  article.criteria = generateArticleCriteria(article);
  const pubs = allPublications.edges.map(item => item.node.publicationDetail);
  const arts = allArticles.edges.map(item => item.node);

  let articles = [...arts, ...pubs]
    .map(general => {
      if (general.articleDetail) {
        general.detail = normalizeArticle(general.articleDetail);
      } else {
        general.detail = general;
      }
      general.detail.generalTitle = general.title;
      return general;
    })
    .filter(
      general =>
        general.contentful_id !== articleID &&
        general.detail?.node_locale === article.node_locale
    );

  articles = removeDuplicates(articles, "contentful_id")
    .sort(
      (a, b) =>
        _.intersection(generateArticleCriteria(b.detail), article.criteria)
          .length -
          _.intersection(generateArticleCriteria(a.detail), article.criteria)
            .length ||
        new Date(b.detail.publicationDateTime) -
          new Date(a.detail.publicationDateTime)
    )
    .slice(0, 5)
    .map(el => el.detail);
  const topArticle = articles[0];
  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === article.node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  const responsive = {
    xl: {
      breakpoint: carouselBreakpoints.xl,
      items: 4,
    },
    l: {
      breakpoint: carouselBreakpoints.l,
      items: 4,
    },
    m: {
      breakpoint: carouselBreakpoints.m,
      items: 3,
    },
    s: {
      breakpoint: carouselBreakpoints.s,
      items: 2,
    },
    xs: {
      breakpoint: carouselBreakpoints.xs,
      items: 1,
      partialVisible: true,
    },
  };

  return (
    <section className="related-articles bg-white">
      <Container fluid>
        <Row>
          <Col className="news__title d-flex align-items-center justify-content-between">
            <h4 className="font-weight-normal">
              {getValue("labels.relatedArticles", localizedMicrocopy)}
            </h4>
            <Link
              className={"news-read-more"}
              to={getLocalizedLink(`/search`, article.node_locale)}
            >
              {getValue("actions.showMore", localizedMicrocopy)}
              <FontAwesomeIcon
                icon={faChevronRight}
                size="lg"
                className="news-read-more-icon"
              />
            </Link>
          </Col>
        </Row>
        {topArticle && (
          <TeaserCard
            className={"mb-4 top-article"}
            title={topArticle.title}
            image={topArticle.imageLandscape.imageLandscape.fluid}
            content={getCappedText(
              getNormalizedReader(topArticle.reader.json),
              300
            )}
            tags={getLocalizedTags(
              topArticle.visibleTags,
              [topArticle.focusOfWork, ...topArticle.topic, topArticle.region],
              topArticle.node_locale
            )}
            createdAt={topArticle.publicationDateTime}
            locale={topArticle.node_locale}
            landscape={true}
            big={true}
            link={getArticleOrPublicationLink(
              topArticle.generalTitle,
              topArticle.__typename,
              topArticle.node_locale
            )}
            readMoreLabelText={getValue("actions.readMore", localizedMicrocopy)}
          />
        )}
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          showDots={true}
          className={"bg-white"}
          containerClass="frient-carousel align-items-start"
          customButtonGroup={<CarouselControl />}
          arrows={false}
          dots={false}
          dotListClass="frient-carousel-dots"
        >
          {articles.map(item => (
            <Col xs={12} className="h-100" key={item.contentful_id}>
              <TeaserCard
                title={item.title}
                tags={getLocalizedTags(
                  item.visibleTags,
                  [item.focusOfWork, ...item.topic, item.region],
                  item.node_locale
                )}
                className={"small-article"}
                createdAt={item.publicationDateTime}
                locale={item.node_locale}
                articleType={
                  _.get(item, ["typeOfArticle", "name"]) ||
                  getValue("labels.publications", localizedMicrocopy)
                }
                link={getArticleOrPublicationLink(
                  item.generalTitle,
                  item.__typename,
                  item.node_locale
                )}
                image={item.imageLandscape.imageLandscape.fluid}
                readMoreLabelText={getValue(
                  "actions.readMore",
                  localizedMicrocopy
                )}
              />
            </Col>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};
/* PropTypes */
RelatedArticles.propTypes = {
  article: PropTypes.object,
  tagInfo: PropTypes.object,
};
RelatedArticles.defaultProps = {
  article: {},
};

/* Local utility functions */

/* Styles */

export default RelatedArticles;
