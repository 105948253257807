import PropTypes from "prop-types";
import React from "react";
import { Carousel } from "react-bootstrap";

import Image from "gatsby-image";

const Gallery = ({ images }) => (
  <div className="gallery">
    <Carousel>
      {images.map(image => {
        return (
          <Carousel.Item>
            <Image fluid={image.fluid} className="d-block w-100" />
          </Carousel.Item>
        );
      })}
    </Carousel>
  </div>
);

/* PropTypes */
Gallery.propTypes = {
  images: PropTypes.array,
};
Gallery.defaultProps = {};

export default Gallery;
