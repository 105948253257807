/* Global imports */
import React from "react";
import PropTypes from "prop-types";

/* Local imports */
import Box from "./box";
import Contact from "./contact";

/* Component definition */
const Contacts = ({ authors, locale }) => (
  <Box
    headline={getHeadline(authors)}
    className={`d-flex authors ${
      authors.length > 1 ? "authors--multiple" : ""
    }`}
    locale={locale}
  >
    {authors.map(author => (
      <Contact
        key={author.name}
        name={author.name}
        email={author.email}
        image={author.image}
        bio={authors.length === 1 ? author.bio : ""}
        isMinimal={authors.length > 1}
      />
    ))}
  </Box>
);

/* PropTypes */
Contacts.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      image: PropTypes.object,
      isMinimal: PropTypes.bool,
      bio: PropTypes.string,
    })
  ),
  locale: PropTypes.oneOf(["de-DE", "en"]),
};
Contacts.defaultProps = {
  authors: [],
  locale: "de-DE",
};

/* Local utility functions */
const getHeadline = authors => {
  if (authors.length > 1 || !authors[0] || (authors[0] && !authors[0].bio)) {
    return "labels.contact";
  } else {
    return "labels.aboutTheAuthor";
  }
};

/* Styles */

export default Contacts;
